import eventBus from "@monorepo/utils/src/eventBus";
import { MONITORING_EVENT_BUS_EVENTS } from "@monorepo/utils/src/eventBus/events/monitoringEvents";
import { CompatClient } from "@stomp/stompjs";
import { parseSocketPacket } from "@monorepo/utils/src/utils/socket/parseSocketPacket";
import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { ComponentInstance } from "@vue/composition-api";

enum SOCKET_NAMES {
  NEW_EVENT = "NEW_EVENT",
}

const useEventsSocketLogic = (stompClient: CompatClient, root: ComponentInstance): void => {
  const store = root.$store;
  stompClient.subscribe("/topic/events", function (event) {
    const response = parseSocketPacket<{ data: IEvent[] }>(event.body);

    if (!response?.type) {
      console.error("Ошибка при получении сообщения!", event);
      return;
    }

    switch (response.type) {
      case SOCKET_NAMES.NEW_EVENT:
        {
          const prevValue = store.state.eventLogView.data.length;
          store.commit("eventLogView/addEvent", response.data || []);
          if (store.state.eventLogView.data.length > prevValue) {
            eventBus.$emit(MONITORING_EVENT_BUS_EVENTS.ADD_NEW_EVENT);
          }
        }
        break;
      default:
        console.warn("Необработанное событие от socket", event);
        break;
    }
  });
};

export default useEventsSocketLogic;
