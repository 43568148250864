


























































import { get } from "lodash";
import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import { mapActions, mapGetters } from "vuex";
import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { modalElements } from "@monorepo/utils/src/variables/projectsData/eventLog/modalElements";
import { convertDataItemToTableView } from "@monorepo/monitoring/src/views/EventLogView/utils/convertDataItemToTableView";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eventLog/viewTitle";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { getAuthorityByType } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/getAuthorityByType";
import { getPath } from "../constants/getPath";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "EventLogInfoModal",
  components: {
    CardModalInfoElement,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
    sectionName: {
      type: String,
      default: "eventLogView",
    },
  },
  data() {
    return {
      element: {} as ReturnType<typeof convertDataItemToTableView>,
      modalElements,
      viewUniqKey,
      openedPanels: [0],
      isLoading: false,
      cardMode: CardMode,
      section: Sections.EVENT,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getEventElement: (val: string) => Promise<IEvent>;
          element: ReturnType<typeof convertDataItemToTableView>;
          isLoading: boolean;
          isShowAnimation: boolean;
          sourceTitles: Record<string, string>;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getEventElement(value)
            .then((data: IEvent) => {
              this.element = {
                ...convertDataItemToTableView((data || {}) as any, true),
                source: this.sourceTitles[data.source] || "",
              };
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
          this.isLoading = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    sourceTitles(): Record<string, string> {
      return (this.sourcesLib || []).reduce((res: Record<string, string>, curr: { title: string; value: string }) => {
        return {
          ...res,
          [curr.value]: curr.title,
        };
      }, {});
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.EVENT_LOG_EXPORT);
    },
    getItemElement(): unknown {
      return (key: string): string | undefined => get(this.element, key);
    },
    isAuthorityExistInGUID(): boolean {
      const authorities = getAuthorityByType(this.element, "section");
      const authoritiesObject = this.user?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
        result[item.authority || ""] = true;
        return result;
      }, {});
      return !!authorities.find((key: string) => authoritiesObject[key]);
    },
    data(): unknown {
      return this.$store.getters[`${this.sectionName}/data`];
    },
    openedId(): number {
      return this.$store.getters[`${this.sectionName}/openedId`];
    },
    sourcesLib(): { title: string; value: string }[] {
      return this.$store.getters[`${this.sectionName}/sourcesLib`];
    },
  },
  methods: {
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    closeModal() {
      this.$emit("close");
    },
    clickItem(item: { value: string; section: string; obj: string }) {
      if (item.value === fields.EVENT_OBJ && this.isAuthorityExistInGUID) {
        this.$router.push({ path: getPath(this.element?.section || ""), query: { id: this.element.obj, isOpenModal: "1" } });
      }
    },
    changeOpenedId(value: any) {
      return this.$store.dispatch(`${this.sectionName}/changeOpenedId`, value);
    },
    getEventElement(value: any) {
      return this.$store.dispatch(`${this.sectionName}/getEventElement`, value);
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, props.sectionName, Sections.EVENT, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, props.sectionName);

    return {
      addQueryOpenedId,
    };
  },
});
