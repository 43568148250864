import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { convertTypeToText } from "@monorepo/monitoring/src/views/EventLogView/utils/convertTypeToText";
import { fields } from "@monorepo/utils/src/variables/projectsData/userProfileJournal/fields";
import moment from "moment";

const truncate = (input: string, length: number): string => (input.length > length ? `${input.substring(0, length)}...` : input);

export interface IResultEvent extends IEvent {
  type: string;
  date: string;
  description: string;
  eventTime: string;
}
export const convertDataItemToTableView = (
  item: IEvent & {
    eventCode?: {
      code: string;
      title: string;
    };
    objectTypeCode?: {
      code: string;
      title: string;
    };
    source?: {
      code: string;
      title: string;
    };
  },
  isFullDescription = false
): IResultEvent => {
  return {
    ...item,
    type: convertTypeToText(item.type),
    date: moment(item.date).format("YYYY-MM-DD HH:mm"),
    eventTime: moment(item.eventTime).format("YYYY-MM-DD HH:mm"),
    description: isFullDescription ? item.description : truncate(item.description || "", 30),
    [fields.SOURCE]: item.source?.title,
    [fields.EVENT_CODE]: item.eventCode?.title,
    [fields.OBJECT_TYPE]: item.objectTypeCode?.title,
  };
};

export const convertApiItemToUi = (
  item: IEvent & {
    source?: {
      code: string;
      title: string;
    };
  }
): IResultEvent => {
  return {
    ...convertDataItemToTableView(item, true),
  };
};
